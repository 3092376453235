<template>
  <Layout>
    <div class="bsat__container my-sm-10 examples">
      <a
        @click="$router.go(-1)"
        class="bsat__btn dark-text px-0 font-weight-bold mb-4"
        style="font-size: 16px !important"
      >
        <v-icon left color="dark">mdi-keyboard-backspace</v-icon>
        {{ $t("back") }}
      </a>

      <div
        class="light_green-text bsat__title font-weight-bold mb-4 pt-4 pt-sm-9"
      >
        {{ $t("title") }}
      </div>

      <!-- <div class="bsat__paragraph">{{ $t("intro") }}</div> -->

      <!-- <a :href="link" target="_blank" rel="noopener noreferrer">
        <v-btn outlined height="26" class="bsat__btn" color="dark">{{
          $t("visitLink")
        }}</v-btn>
      </a> -->

      <div class="bsat__paragraph">{{ $t("paragraph1") }}</div>

      <i18n path="paragraph2.text" tag="div" class="bsat__paragraph">
        <template #strong>
          <strong>{{ $t("paragraph2.strong") }}</strong>
        </template>
      </i18n>

      <div class="bsat__paragraph">{{ $t("paragraph3") }}</div>

      <v-data-table
        :headers="headers"
        :items="items"
        class="border"
        :items-per-page="12"
        hide-default-footer
      >
        <template #item.app="{item}">
          <strong>{{ item.app }}</strong>
        </template>
        <template #item.link="{ item }">
          <a :href="item.link" target="_blank" rel="noopener noreferrer">
            <v-btn outlined height="26" class="bsat__btn mb-0" color="dark">{{
              $t("visit")
            }}</v-btn>
          </a>
        </template>
      </v-data-table>
    </div>
  </Layout>
</template>

<i18n>
    {
        "en": {
            "back": "Back",
            "title": "Best practice examples of bio-based solutions with replicability potential",
            "intro": "We invite you to get inspired by the POWER4BIO catalogue of bio-based solutions about key enabling aspects to develop and/or good practices to deploy at regional level.",
            "visitLink": "Visit POWER4BIO catalogue",
            "paragraph1": "The catalogue contains 26 bio-based solutions about key enabling aspects to develop and/or good practices to deploy at regional level. The factsheets include tested potential for market uptake and a high technical readiness level (TRL) for bioenergy production, biomaterials, biochemicals as well as for food and feed. All factsheets include a short description and several characteristics of the solution, as well as information regarding the suitability of biomass feedstock for production of the target product, benefits of the solution related to the value chain and specific constraints for implementation. ",
            "paragraph2": {
                "text": "Among the 26 solutions, we encourage you to consult the following 12 bio-based solutions from the POWER4BIO catalogue. These highlighted solutions show best practice examples in Europe of industrial production sites, providing information about their potential for {strong}. You will gain access to extensive information to understand each case, including critical elements like financial and incentives elements as well as value chain boundary conditions. ",
                "strong": "replicability in rural environments and highly competitive"
            },
            "paragraph3": "For each solution, consult section “Environment and Impacts” in order to learn about rural potential and competitiveness of the solution.",
            "tableHeaders": {
              "app": "Bio-application",
              "solution": "Bio-based solution",
              "name": "Name of Companies"
            }
        }
    }
</i18n>

<script>
export default {
  metaInfo: {
    title:
      "Best practice examples of bio-based solutions with replicability potential"
  },
  components: {
    Layout: () => import("@/layouts/default")
  },
  data() {
    return {
      link: "https://www.bio-based-solutions.eu/#/",
      items: [
        {
          app: "Bio-energy",
          solution:
            "1. Production of biogas from cattle manure anaerobic digestion. ‘Green gas Kampen (Dairy farmer de Groot)",
          name: "HOST",
          link: "https://www.bio-based-solutions.eu/#/project/102"
        },
        {
          app: "",
          solution:
            "2. Bio-coal production via Hydrothermal Carbonization (HTC) of Sewage Sludge",
          name: "TERRANOVA",
          link: "https://www.bio-based-solutions.eu/#/project/103"
        },
        {
          app: "",
          solution:
            "3. Production of renewable hydrogen via thermolysis of biomass",
          name: "HAFFNER ENERGY ",
          link: "https://www.bio-based-solutions.eu/#/project/101"
        },
        {
          app: "Food and Bioenergy",
          solution:
            "4. Oyster mushroom and oyster mushroom substrate production on cereal crop straw",
          name: "PILZE-NAGY",
          link: "https://www.bio-based-solutions.eu/#/project/129"
        },
        {
          app: "Bio-materials",
          solution:
            "5. Sustainable insulation and construction material made by using hemp hurds",
          name: "HEMPIRE",
          link: "https://www.bio-based-solutions.eu/#/project/140"
        },
        {
          app: "",
          solution:
            "6. Meadow grass silage biorefinery producing grass fibre enhanced plastic granulates and natural insulation material",
          name: "BIOWERT",
          link: "https://www.bio-based-solutions.eu/#/project/161"
        },
        {
          app: "Bio-chemicals",
          solution:
            "7. Production of 5-HMF from 1st generation sugars such as sugar beet and corn",
          name: "AVA-BIOCHEM",
          link: "https://www.bio-based-solutions.eu/#/project/94"
        },
        {
          app: "",
          solution:
            "8. Sugar production from lignocellulosic biomass through hydrolysis",
          name: "GREEN SUGAR",
          link: "https://www.bio-based-solutions.eu/#/project/83"
        },
        {
          app: "",
          solution:
            "9. Production of 1,4-butanediol (BDO) directly through fermentation of sugars derived from the hydrolysis of starch",
          name: "MATER-BIOTECH",
          link: "https://www.bio-based-solutions.eu/#/project/171"
        },
        {
          app: "Food and Feed",
          solution:
            "10. Bioconversion of organic side streams by black soldier fly - producing insects, lipid & protein for feed",
          name: "BESTICO",
          link: "https://www.bio-based-solutions.eu/#/project/148"
        },
        {
          app: "",
          solution: "11. Growing mushrooms on coffee grounds",
          name: "ROTTERZWAM",
          link: "https://www.bio-based-solutions.eu/#/project/89"
        },
        {
          app: "",
          solution:
            "12. Production of food additives from olive oil industry by-products by extraction",
          name: "INNOVAOLEO",
          link: "https://www.bio-based-solutions.eu/#/project/90"
        }
      ]
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("tableHeaders.app"),
          value: "app",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: true,
          divider: true
        },
        {
          text: this.$t("tableHeaders.solution"),
          value: "solution",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false
        },
        {
          text: this.$t("tableHeaders.name"),
          value: "name",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false
        },
        {
          text: "",
          value: "link",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false
        }
      ];
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
.border {
  border: 1px solid $light_green;
}

.examples {
  // .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  //     border-bottom: none
  // }
}
</style>
